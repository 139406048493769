.alignmentModalContainer .body {
    white-space: nowrap;
    /* background: #f9f9f9; */
}

.alignmentModalContainer .fixed {
    position: fixed;
    font-weight: 700;
    /* padding-left: 40px; */
    margin-top: -30px;
    background: linear-gradient(0deg, #f2f2f2, lightgrey);
    border-radius: 2px;
    border-bottom: 1px solid lightgrey;
    opacity: 0.8;
}

.alignmentModalContainer .sample {
    margin: 2px;
    background: linear-gradient(0deg, #f2f2f2, lightgrey);
    border-bottom: 1px solid lightgrey;
    opacity: 0.8;
}

.alignmentModalContainer .wrapper {
    margin-top: 5px;
}

.alignmentModalContainer .collection a.collection-item {
    color: #0d47a1;
}

.alignmentModalContainer .collection a.collection-item.active {
    color:#0d47a1
}

.alignmentModalContainer .small-font {
    font-size: 11px;
}

.alignmentModalContainer body.dragging, 
.alignmentModalContainer body.dragging * {
    cursor: move !important;
}

.alignmentModalContainer .dragged {
    position: absolute;
    opacity: 0.5;
    z-index: 2000;
}

.alignmentModalContainer .drag-item {
    /* border: 1px solid #f2f2f2; */
}

.alignmentModalContainer .sample {
    height: 25px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.alignmentModalContainer ol.drag li.placeholder {
    position: relative;
    height: 50px;
    background-color: #ccffff;
    border: 1px dashed grey;
    border-radius: 2px;
}

.alignmentModalContainer ol.drag li.placeholder:before {
    position: absolute;
    content: url(img/drop.png);
}

.alignmentModalContainer .fullscreen {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    margin: 0;
    padding: 0;
    background: inherit;
}
