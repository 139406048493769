    div.body {
        white-space: nowrap;
        background: #f9f9f9;
    }

    .fixed {
        position: fixed;
        font-weight: 700;
        padding-left: 40px;
        margin-top: -30px;
        background: linear-gradient(0deg, #f2f2f2, lightgrey);
        border-radius: 2px;
        border-bottom: 1px solid lightgrey;
        opacity: 0.8;
    }

    .sample {
        margin: 2px;
        background: linear-gradient(0deg, #f2f2f2, lightgrey);
        border-bottom: 1px solid lightgrey;
        opacity: 0.8;
    }

    .wrapper {
        margin-top: 5px;
    }

    .collection a.collection-item {
        color: #0d47a1;
    }

    .collection a.collection-item.active {
        color:#0d47a1
    }

    .small-font {
        font-size: 11px;
    }

    /* Drag n Drop */
    body.dragging, body.dragging * {
        cursor: move !important;
    }

    .dragged {
        position: absolute;
        opacity: 0.5;
        z-index: 2000;
    }

    .drag-item {
        /* border: 1px solid #f2f2f2; */
    }

    .sample {
        height: 25px;;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
    }

    ol.drag li.placeholder {
        position: relative;
        /** More li styles **/
        height: 50px;
        background-color: #ccffff;
        border: 1px dashed grey;
        border-radius: 2px;
        
    }
    ol.drag li.placeholder:before {
        position: absolute;
        /** Define arrowhead **/
        content:  url(img/drop.png);
    }

    .fullscreen {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        margin: 0;
        padding: 0;
        background: inherit;
    }



